import React from 'react';
import { Link } from 'gatsby';

export default function NotFoundPage({ pageContext }) {

  return (
    <div className="not-found">
      <h1>404</h1>
      <Link className="not-found__link" to={`/`}>
        Take me back to the homepage
      </Link>
    </div>
  );
}
